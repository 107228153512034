import { useRouter } from 'next/router';

import { BaseButton } from '@/screen/components/ui/BaseButton';

export const Main = (): JSX.Element => {
  const { back } = useRouter();

  return (
    <section className="bowl-content min-h-screen py-14 flex items-center flex-col justify-center text-center">
      <div className="max-w-3xl">
        <h1 className="font-black text-6xl sm:text-9xl">404</h1>

        <h3 className="mt-2 font-bold text-lg sm:text-2xl">
          Página não encontrada
        </h3>

        <BaseButton
          onClick={back}
          className="px-8 mt-6 mx-auto flex items-center font-bold duration-200 rounded-full text-white bg-gradient-to-r to-floor-green from-floor-green-300 z-10 relative"
        >
          <span className="text-sm sm:text-lg">VOLTAR Á PÁGINA ANTERIOR</span>
        </BaseButton>
      </div>
    </section>
  );
};

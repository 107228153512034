import { Main } from '@/screen/atoms/404/Main';
import { SEO } from '@/screen/components/forward/SEO';

const NotFound = (): JSX.Element => (
  <>
    <SEO title="Página não encontrada" />

    <Main />
  </>
);

export default NotFound;
